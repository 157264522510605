import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { sendEvent } from '@utils/Analytics';
import { getRedirectUrl, InitAuthProps } from '@utils/AuthFlow';
import { safeRedirect } from '@utils/UrlUtils';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useSetUserLocale } from '@utils/Data/Admin/Locale/useSetUserLocale';
import { log } from 'cf-common/src/logger';

import { TokenError } from '@globals';

import { Oops } from '../Oops';

import { sendAfterLoginEvents } from '../MultiSystemAuth/utils/sendAfterLoginEvents';
import { afterLoginActions } from '../MultiSystemAuth/utils/afterLoginActions';
import { setAuthToken } from '../MultiSystemAuth/utils/setAuthToken';
import { redirectToAwesomePanel } from '@utils/AuthFlow/redirectToAwesomePanel';
import { isSwitchFromAwesomePanelSession } from '@utils/switchFromAwesomePanel';

const GENERATE_TOKEN_MUTATION = gql`
  mutation GenerateTokenMutationAuth(
    $code: String!
    $redirectUrl: String!
    $state: String!
    $forceAuth: Boolean!
  ) {
    generateTokenFromRedirect(
      code: $code
      redirectUrl: $redirectUrl
      state: $state
      forceAuth: $forceAuth
    ) {
      token
      error_type
      success
      redirect_to_awesome_panel
      awesome_panel_auth_token
    }
  }
`;

// https://developers.facebook.com/docs/facebook-login/manually-build-a-login-flow#nonjscancel
const FB_CANCEL_LOGIN_PARAMS: Record<string, string> = {
  error_reason: 'user_denied',
  error: 'access_denied',
  error_description: 'Permissions error',
};

const userCanceledLogin = (query: URLSearchParams) =>
  Object.keys(FB_CANCEL_LOGIN_PARAMS).every(
    (param) => query.get(param) === FB_CANCEL_LOGIN_PARAMS[param],
  );

const getParsedState = (state: string | null) => {
  let parsedState: Record<string, string> = {};
  if (state) {
    try {
      parsedState = JSON.parse(Array.isArray(state) ? state[0] : state);
    } catch (error) {
      log.warn({ error, msg: 'Cannot parse state param in auth link' });
    }
  }
  return parsedState;
};

interface AuthProps extends InitAuthProps {}

export const Auth: React.FC<AuthProps> = (props) => {
  const { location, client } = props;
  const [unhandledError, setUnhandledError] = useState<string>();
  const [handledError, setHandledError] = useState<string>();
  const { t } = useSafeTranslation();
  const setUserLocale = useSetUserLocale();

  const query = new URLSearchParams(location.search);

  const code = query.get('code');
  const stateString = query.get('state');
  const redirectUrl = getRedirectUrl();
  const state = getParsedState(stateString);
  const {
    interruptedHref = '/',
    ...redirectState // eslint-disable-line prefer-const
  } = state;

  const redirectParamsSearch = new URLSearchParams(redirectState).toString();
  const allStateParamsSearch = new URLSearchParams(state).toString();

  const handleAuthFlow = async () => {
    if (userCanceledLogin(query)) {
      safeRedirect(`${interruptedHref}?${redirectParamsSearch}`);
    }

    try {
      const forceAuth = isSwitchFromAwesomePanelSession();

      const { data } = await client.mutate({
        mutation: GENERATE_TOKEN_MUTATION,
        variables: {
          code,
          redirectUrl,
          state: JSON.stringify(redirectState),
          forceAuth,
        },
      });

      // data.success
      if (!data || !data.generateTokenFromRedirect) {
        setUnhandledError('No data in generateTokenFromRedirect');
        return;
      }

      const {
        token,
        success,
        error_type,
        redirect_to_awesome_panel,
        awesome_panel_auth_token,
      } = data.generateTokenFromRedirect;

      if (redirect_to_awesome_panel && awesome_panel_auth_token) {
        redirectToAwesomePanel(awesome_panel_auth_token);
        return;
      }

      if (!success) {
        switch (error_type) {
          case TokenError.FB_PROFILE_CONFLICT:
            sendEvent({
              category: 'connect facebook page dialog',
              action: 'fb account conflict',
            });
            setHandledError(t('pages.Auth.errors.fbProfileConflict'));
            break;
          case TokenError.CHATFUEL_ACCOUNT_CONFLICT:
            sendEvent({
              category: 'connect facebook page dialog',
              action: 'chatfuel account conflict',
            });
            setHandledError(t('pages.Auth.errors.chatfuelProfileConflict'));
            break;
          default:
            setUnhandledError(error_type);
        }
        return;
      }

      await setAuthToken(token);

      await sendAfterLoginEvents();

      const isShopifyOnboarding =
        interruptedHref.includes('shopify/choose-bot');
      if (isShopifyOnboarding) {
        safeRedirect(interruptedHref);
        return;
      }
      await setUserLocale();
      await afterLoginActions(interruptedHref);
    } catch (error) {
      log.warn({ error, msg: 'Error while generating token on auth page' });
      setUnhandledError((error as any).toString());
    }
  };

  useEffect(() => {
    handleAuthFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasError = Boolean(unhandledError || handledError);

  if (!hasError) return null;

  const { Button, Guide, Header, Icon, StatusLink } = Oops;

  return (
    <Oops
      reason="Error on auth"
      errorText={unhandledError || handledError}
      renderPage={() => (
        <>
          <Icon />
          <Header
            title={t('Auth-string--176-uh-oh')}
            subtitle={t(
              'Auth-string-5848-there-was-an-error-logging-you-into-facebook',
            )}
          />
          <Guide>
            {handledError || (
              <>
                {t('Auth-JSXText--547-please-try-again-or-checking')}
                <br />
                <StatusLink title={t('Auth-string-1356-the-status-page')} />
              </>
            )}
          </Guide>
          <Button
            title={t('Auth-string-6322-try-again')}
            onClick={() => {
              sendEvent(
                {
                  category: 'error page',
                  action: 'click',
                  label: 'try again',
                },
                true,
              );
              safeRedirect(`/autopermissionsrequest?${allStateParamsSearch}`);
            }}
          />
        </>
      )}
    />
  );
};
