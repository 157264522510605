import { useEffect } from 'react';
import { afterLoginActions } from '@pages/MultiSystemAuth/utils/afterLoginActions';
import { sendAfterLoginEvents } from '@pages/MultiSystemAuth/utils/sendAfterLoginEvents';
import { setAuthToken } from '@pages/MultiSystemAuth/utils/setAuthToken';
import { useSetUserLocale } from '@utils/Data/Admin/Locale/useSetUserLocale';
import { useSearchParams } from '@utils/UrlUtils/useSearchParams';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { log } from 'cf-common/src/logger';

export const AUTH_BY_AWESOME_PANEL_MUTATION = gql`
  mutation AuthByAwesomePanel($token: String!) {
    authByAwesomePanel(token: $token) {
      token
      error_type
      success
      redirect_to_awesome_panel
      awesome_panel_auth_token
    }
  }
`;

export const AuthByAwesomePanel = () => {
  const { searchParams } = useSearchParams();
  const code = searchParams.get('code');
  const [authMutation] = useMutation(AUTH_BY_AWESOME_PANEL_MUTATION);
  const setUserLocale = useSetUserLocale();

  useEffect(() => {
    if (!code) return;

    const auth = async () => {
      try {
        const { data } = await authMutation({
          variables: {
            token: code,
          },
        });

        if (!data?.authByAwesomePanel.token) return;

        await setAuthToken(data.authByAwesomePanel.token);
        await sendAfterLoginEvents();
        await setUserLocale();
        await afterLoginActions();
      } catch (error) {
        log.error({ error, msg: 'Error auth by awesome panel on auth page' });
      }
    };

    auth();
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
